import { createContext, useReducer, useCallback } from 'react';
import { SET_OFFERS } from 'variables';

////////////////////////////////////////
const OffersContext = createContext({
    offers: null,
    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    setOffers: (data) => { },
});
////////////////////////////////////////
const offersReducer = (state, action) => {
    switch (action.type) {
        case SET_OFFERS:
            return {
                ...state,
                offers: action.offers
            }
        default:
            return state
    }
}
////////////////////////////////////////
const initState = {
    offers: null
}
export const OffersContextProvider = (props) => {

    const [offersState, dispatch] = useReducer(offersReducer, initState);

    //////////////////////////////////
    const setOffers = useCallback((data) => {
        dispatch({ type: SET_OFFERS, offers: data })
        // dispatch({
        //     type: SET_OFFERS, offers: [
        //         {
        //             "id": 1,
        //             "title": "Lababak 1",
        //             "body": "Because we care about your time, we are introducing Lababak  servcie",
        //             "image": "https://jico-admin.msalahat.me/uploads/ckq64gfsl0000rgcthuauet2d-lababak-news.jpeg"
        //         },
        //         {
        //             "id": 2,
        //             "title": "Offer name may come in 1 or 2 lines but not more",
        //             "body": "Because we care about your time, we are introducing Lababak  servcie",
        //             "image": "https://jico-admin.msalahat.me/uploads/ckq64gfsl0000rgcthuauet2d-lababak-news.jpeg"
        //         },
        //         {
        //             "id": 3,
        //             "title": "Lababak 3",
        //             "body": "Because we care about your time, we are introducing Lababak  servcie",
        //             "image": "https://jico-admin.msalahat.me/uploads/ckq64gfsl0000rgcthuauet2d-lababak-news.jpeg"
        //         },
        //         {
        //             "id": 4,
        //             "title": "Lababak 4",
        //             "body": "Because we care about your time, we are introducing Lababak  servcie",
        //             "image": "https://jico-admin.msalahat.me/uploads/ckq64gfsl0000rgcthuauet2d-lababak-news.jpeg"
        //         },
        //     ]
        // })
    }, [])
    //////////////////////////////////

    const context = {
        offers: offersState.offers,
        setOffers: setOffers
    }

    return (
        <OffersContext.Provider value={context}>
            {props.children}
        </OffersContext.Provider>
    )
}

export default OffersContext;