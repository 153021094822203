import { createContext, useReducer, useCallback, useEffect } from 'react';
import { SET_LANGUAGE } from 'variables';
import { languages } from 'languages.js';
import { useLocation, useHistory } from "react-router-dom";
import { getDefaultLanguage, getLang, getFullPathWithoutLang } from 'utility';
import { set_accepted_lang } from 'services/http.service'
////////////////////////////////////////

const layoutReducer = (state, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            const lang = languages[action.lang]
            return {
                ...state,
                ...lang
            }
        default:
            return state
    }
}
////////////////////////////////////////
const LayoutContext = createContext({
    lang: "en",
    label: "English",
    direction: "ltr",
    defaultDirection: "left",
    oppositeDirection: "right",
    fontFamily: "Latin",
    default: false,
    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    setLanguage: (lang) => { }
});

const lang = getLang(window.location.pathname);
const initState = getDefaultLanguage(lang);


////////////////////////////////////////
export const LayoutContextProvider = (props) => {
    const location = useLocation();
    const history = useHistory();


    const [layoutState, dispatch] = useReducer(layoutReducer, initState);

    //////////////////////////////////
    useEffect(() => {
        set_accepted_lang(layoutState.lang)
    }, [layoutState.lang])
    //////////////////////////////////
    const addLangOverHtmlTag = (lang) => {
        const root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', lang === "en" ? "Latin" : "Arabic");
    }
    //////////////////////////////////
    const setLanguage = useCallback((lang) => {
        let path = getFullPathWithoutLang(location.pathname);
        if (path === 'en' || path === 'ar'){
            path = '';
        }
        dispatch({ type: SET_LANGUAGE, lang: lang })
        history.push(`/${lang}/${path}${location.search}`)
        addLangOverHtmlTag(lang)
    }, [history, location])

    //////////////////////////////////

    const context = {
        lang: layoutState.lang,
        label: layoutState.label,
        direction: layoutState.direction,
        defaultDirection: layoutState.defaultDirection,
        oppositeDirection: layoutState.oppositeDirection,
        fontFamily: layoutState.fontFamily,
        setLanguage: setLanguage
    }

    return (
        <LayoutContext.Provider value={context}>
            {props.children}
        </LayoutContext.Provider>
    )
}

export default LayoutContext;