export const GET_URL_PATH_FIRST_PART = 'GET_URL_PATH_FIRST_PART';
export const GET_URL_PATH_LAST_PART = 'GET_URL_PATH_LAST_PART';
export const GET_FULL_PATH_WITHOUT_LANG = 'GET_FULL_PATH_WITHOUT_LANG';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_USER_INFO = 'SET_USER_INFO';

export const SET_POLICY_TYPE = 'SET_POLICY_TYPE';

export const START_API = 'START_API';
export const SUCCESS_API = 'SUCCESS_API';
export const FAILED_API = 'FAILED_API';
export const SUCCESS_API_LOCATION ='SUCCESS_API_LOCATION';
export const SUCCESS_API_GETPOLICY = 'SUCCESS_API_GETPOLICY';
export const SUCCESS_APPOINTMENTS = 'SUCCESS_APPOINTMENTS';
export const START_BLOCKED_DAYS_API = 'START_BLOCKED_DAYS_API';
export const SUCCESS_BLOCKED_DAYS = 'SUCCESS_BLOCKED_DAYS';

export const START_PAYMENT = 'START_PAYMENT';
export const SUCCESS_PAYMENT = 'SUCCESS_PAYMENT';
export const FAILED_PAYMENT = 'FAILED_PAYMENT';

export const START_LABABAK = 'START_LABABAK';
export const SUCCESS_LABABAK = 'SUCCESS_LABABAK';
export const FAILED_LABABAK = 'FAILED_LABABAK';


export const SET_NATIONALITIES = 'SET_NATIONALITIES';
export const SET_CITIES = 'SET_CITIES';
export const SET_INCOMES = 'SET_INCOMES';
export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const SET_MOBILE_NUMBER = 'SET_MOBILE_NUMBER';
export const SET_VALIDATE_PHONE_NUMBER = 'SET_VALIDATE_PHONE_NUMBER';
export const REGISTRATION_AGREE = 'REGISTRATION_AGREE';

export const SIGNIN_FORM = 'SIGNIN_FORM';
export const SIGNUP_FORM = 'SIGNUP_FORM';

// export const SET_OTP_INPUT = 'SET_OTP_INPUT';
export const RESET_FORM = 'RESET_FORM';
export const INVALID_OTP_CODE = 'INVALID_OTP_CODE';
export const RESEND_OTP_CODE = 'RESEND_OTP_CODE';
export const SWITCH_TAB = 'SWITCH_TAB';


export const SET_OFFERS = 'SET_OFFERS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_POLICIES = 'SET_POLICIES';
export const SET_POLICY = 'SET_POLICY';
export const SET_POLICY_URL = 'SET_POLICY_URL';


export const INITIAL_PHONE_NUMBER = 'INITIAL_PHONE_NUMBER';

