/* App settings */
const Settings = {
    ...process.env
}

Settings.format = {
    email: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!$%@#£€*?&_=+-]{6,}$/,
    fullName: /.+\s+\S.+\s+\S.+\s+\S/,
    notEmptyString: /^(?!\s*$)/,
    age: /^([1-9]|[1-5][0-9]|59)$/,
    // notEmptyString: /^\s*$/,
    // notEmptyString: /([^\s]*)/,
    expiryDate: /^[0-9]{2}\/[0-9]{2}$/,
    phonenumber: /^[0-9]{9,14}$/,
    cvc: /^[0-9]{3}$/,
    true: /true/i,
    false: /false/i
};

Settings.get = function (setting) {
    return Settings[setting];
};

export default Settings;