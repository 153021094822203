import { createContext, useState, useContext, useCallback } from 'react';
import { allKeys } from 'translation/translation';
import LayoutContext from 'stores/layout.context';
import { getDefaultLanguage } from 'utility';

////////////////////////////////////////
const TranslationContext = createContext({
    keys: {},
    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    translate: (key) => { },
});
////////////////////////////////////////
export const TranslationContextProvider = (props) => {

    const [translationState,] = useState(allKeys);
    const { lang } = useContext(LayoutContext);

    //////////////////////////////////
    const translate = useCallback((key) => {
        const defaultLang = lang ? lang : getDefaultLanguage().lang;
        if (translationState[defaultLang][key])
            return translationState[defaultLang][key];
        return key;
    }, [translationState, lang])
    //////////////////////////////////
    const context = {
        translate: translate
    }
    //////////////////////////////////
    return (
        <TranslationContext.Provider value={context}>
            {props.children}
        </TranslationContext.Provider>
    );
}

export default TranslationContext;