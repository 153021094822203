import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LayoutContextProvider } from 'stores/layout.context'
import { TranslationContextProvider } from 'stores/translation.context'
import { UserContextProvider } from 'stores/user.context'
import { OffersContextProvider } from 'stores/offers.context'
import { NotificationsContextProvider } from 'stores/notifications.context'
import { PoliciesContextProvider } from 'stores/policies.context'
import { PolicyTypeContextProvider } from 'stores/policy-type.context'

ReactDOM.render(

  <BrowserRouter>
    <LayoutContextProvider>
      <TranslationContextProvider>
        <UserContextProvider>
          <PoliciesContextProvider>
            <OffersContextProvider>
              <NotificationsContextProvider>
                <PolicyTypeContextProvider>
                  <App />
                </PolicyTypeContextProvider>
              </NotificationsContextProvider>
            </OffersContextProvider>
          </PoliciesContextProvider>
        </UserContextProvider>
      </TranslationContextProvider>
    </LayoutContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();