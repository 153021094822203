import { createContext, useReducer, useCallback } from 'react';
import { SET_POLICY_TYPE } from 'variables';

////////////////////////////////////////
const PolicyTypeContext = createContext({
    policyType: null,
    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    setPolicyType: (data) => { },
});
////////////////////////////////////////
const policyReducer = (state, action) => {
    switch (action.type) {
        case SET_POLICY_TYPE:
            return {
                ...state,
                policyType: action.policyType,
            }
        default:
            return state
    }
}
////////////////////////////////////////
const initState = {
    policyType: null
}
export const PolicyTypeContextProvider = (props) => {

    const [policyState, dispatch] = useReducer(policyReducer, initState);

    //////////////////////////////////
    const setPolicyType = useCallback((data) => {
        dispatch({ type: SET_POLICY_TYPE, policyType: data })
    }, [])
    //////////////////////////////////

    const context = {
        policyType: policyState.policyType,
        setPolicyType: setPolicyType
    }

    return (
        <PolicyTypeContext.Provider value={context}>
            {props.children}
        </PolicyTypeContext.Provider>
    )
}

export default PolicyTypeContext;