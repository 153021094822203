/* HTTP service */
import axios from 'axios';
import Settings from './settings.service';

let device = "";
let authToken = "";
let acceptedLang = "en";

const HTTP = axios.create({
  baseURL: Settings.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
HTTP.defaults.headers.common.Accept = 'application/json';
///////////////////////////////
HTTP.interceptors.request.use(
  config => {
    config.headers['accept-language'] = acceptedLang; // [TODO] get value from context api
    if (config.headers['needAutherization'] === undefined) {
      config.headers['Authorization'] = authToken;
    }
    delete config.headers['needAutherization']
    return config;
  },
  error => Promise.reject(error)
);
///////////////////////////////////
HTTP.get_device_id = () => {
  return device;
};
/////////////////////////////////
export const set_device_id = val => {
  device = val;
};
/////////////////////////////////
export const set_session = val => {
  authToken = val;
};
/////////////////////////////////
export const set_accepted_lang = val => {
  acceptedLang = val;
};
/////////////////////////////////
export const get_session = () => {
  return authToken;
};
/////////////////////////////////
export default HTTP;