import { createContext, useReducer, useCallback } from 'react';
import { SET_NOTIFICATIONS } from 'variables';

////////////////////////////////////////
const NotificationsContext = createContext({
    notifications: null,

    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    setNotifications: (data) => { },
});
////////////////////////////////////////
const notificationsReducer = (state, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            }
        default:
            return state
    }
}
////////////////////////////////////////
const initState = {
    notifications: null
}
export const NotificationsContextProvider = (props) => {

    const [notificationsState, dispatch] = useReducer(notificationsReducer, initState);

    //////////////////////////////////
    const setNotifications = useCallback((data) => {
        // dispatch({ type: SET_NOTIFICATIONS, notifications: data })
        dispatch({
            type: SET_NOTIFICATIONS, notifications: [
                {
                    "id": 1,
                    "message": "Thank you for using the new JICO web portal.",
                    "date": "5 mins ago"
                },
                
            ]
        })
    }, [])
    //////////////////////////////////

    const context = {
        notifications: notificationsState.notifications,
        setNotifications: setNotifications
    }

    return (
        <NotificationsContext.Provider value={context}>
            {props.children}
        </NotificationsContext.Provider>
    )
}

export default NotificationsContext;