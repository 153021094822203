import { createContext, useReducer, useCallback } from 'react';
import { SET_POLICIES } from 'variables';

////////////////////////////////////////
const PoliciesContext = createContext({
    policies: null,
    fetched: false,
    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    setPolicies: (data) => { },
});
////////////////////////////////////////
const policiesReducer = (state, action) => {
    switch (action.type) {
        case SET_POLICIES:
            return {
                ...state,
                policies: action.policies,
                fetched: true
            }
        default:
            return state
    }
}
////////////////////////////////////////
const initState = {
    policies: null,
    fetched: false
}
export const PoliciesContextProvider = (props) => {

    const [policiesState, dispatch] = useReducer(policiesReducer, initState);

    //////////////////////////////////
    const setPolicies = useCallback((data) => {
        dispatch({ type: SET_POLICIES, policies: data })
    }, [])
    //////////////////////////////////

    const context = {
        policies: policiesState.policies,
        setPolicies: setPolicies
    }

    return (
        <PoliciesContext.Provider value={context}>
            {props.children}
        </PoliciesContext.Provider>
    )
}

export default PoliciesContext;