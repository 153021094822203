import HTTP from 'services/http.service';
/////////////////////////////////////////
export const getUserAuth = function (UserData) {
    const config = {
        method: 'post',
        url: '/session',
        data: UserData,
        headers: {
            needAutherization: false
        }
    };
    return HTTP(config);
}
/////////////////////////////////////////
export const getUserLogin = function (authToken) {
    const config = {
        method: 'get',
        url: '/user/current',
        headers: {
            Authorization: authToken
        }

    };
    return HTTP(config);
}
/////////////////////////////////////////
export const registration = async (data) => {
    const config = {
        method: 'post',
        url: '/user',
        data: data
    };
    return HTTP(config);
}
/////////////////////////////////////////
export const updateUserInfo = async (data) => {
    const config = {
        method: 'patch',
        url: '/user',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const changeUserPassword = async (data) => {
    const config = {
        method: 'patch',
        url: '/user/change-password',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const forgetUserPassword = async (data) => {
    const config = {
        method: 'post',
        url: '/user/forget-password',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const resetUserPassword = async (data) => {
    const config = {
        method: 'post',
        url: '/user/reset-password',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const login = async (data) => {
    const config = {
        method: 'post',
        url: '/user/login',
        data: data
    };
    return HTTP(config);
}
/////////////////////////////////////////
export const requestOtp = function (data) {
    const config = {
        method: 'post',
        url: '/otp/send',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const verifyOtp = function (data) {
    const config = {
        method: 'post',
        url: '/otp/verify',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const userAnnouncement = function () {
    const config = {
        method: 'get',
        url: '/announcement/home'
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const travelQuotation = function (data) {
    const config = {
        method: 'post',
        url: '/travel/quotation',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const travelQuotationLowCost = function (data) {
    const config = {
        method: 'post',
        url: '/travel/quotationlowcost',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const travelPolicy = function (data) {
    const config = {
        method: 'post',
        url: '/travel/policy',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const carQuotation = function (data) {
    const config = {
        method: 'post',
        url: '/car/quotation',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const carPolicy = function (data) {
    const config = {
        method: 'post',
        url: '/car/policy',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const personalGuardQuotation = function () {
    const config = {
        method: 'post',
        url: '/guard/quotation',
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const personalGuardPolicy = function (data) {
    const config = {
        method: 'post',
        url: '/bodyguard/policy',
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getUserPolicies = function () {
    const config = {
        method: 'get',
        url: '/user/polices'
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getUserPoliciesByType = function (type) {
    const config = {
        method: 'get',
        url: `/user/${type}/polices`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getPolicyPdf = function (policy_id, policy_type) {
    const config = {
        method: 'get',
        url: `/user/polices/${policy_type}/${policy_id}/export`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getTimes = function (date) {
    const config = {
        method: 'get',
        url: `/booking/getTimes/${date}`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const createAppointment = function (data) {
    const config = {
        method: 'post',
        url: `/booking/createAppointment`,
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const updateAppointment = function (data) {
    const config = {
        method: 'post',
        url: `/booking/updateAppointment`,
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getAppointment = function () {
    const config = {
        method: 'get',
        url: `/booking/getCustomerAppointment/`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getAppointmentbyCustomerId = function (customerId) {
    const config = {
        method: 'get',
        url: `/booking/getCustomerAppointment/${customerId}`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const cancleUserAppointment = function (id ,data) {
    const config = {
        method: 'delete',
        url: `/booking/cancleAppointment/${id}`,
        params: {notes: data}
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const cancleUserAppointmentbyCustomerId = function (id ,customerId, data) {
    const config = {
        method: 'delete',
        url: `/booking/cancleAppointment/${id}/${customerId}`,
        params: {notes: data}
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getChequeState = function (data) {
    const config = {
        method: 'post',
        url: `/financial/getchequestatus`,
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getLocationApproval = function (data) {
    const config = {
        method: 'get',
        url: `/booking/getLocationApproval/${data}`,
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getPolicyBySegmentCode = function (data) {
    const config = {
        method: 'get',
        url: `/user/getPolicyBySegmentCode`,
        params: {segmentcode: data}
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getAllAppointments = function () {
    const config = {
        method: 'get',
        url: `/booking/getAllAppointments`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getAppointmentById = function (id) {
    const config = {
        method: 'get',
        url: `/booking/getAppointmentById/${id}`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const initiateJourney = function (data) {
    const config = {
        method: 'post',
        url: `/user/initiate`,
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const qrCodePolicy = function (data) {
    const config = {
        method: 'get',
        url: `/qrcode/policy`,
        params: {token: data}
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const notificationsRegister = function (data) {
    const config = {
        method: 'post',
        url: `/notifications/register`,
        data: {token: data}
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const getBlockedDays = function () {
    const config = {
        method: 'get',
        url: `/booking/getBlockedDays`
    };
    return HTTP(config);
};
/////////////////////////////////////////
export const sendEmail = function (data) {
    const config = {
        method: 'post',
        url: `/email/send`,
        data: data
    };
    return HTTP(config);
};
/////////////////////////////////////////