export const allKeys = {
  en: {
    login_with_your_account: "Log in with JICO's account!",
    email: "Email address",
    password: "Password",
    login_btn_label: "Log in",
    forget_password: "Forgotten your Password?",
    welcome_to_jerusalem_insurance: "Welcome to Jerusalem Insurance",
    wrong_email_format: "Wrong email format",
    dont_have_account: "Don't you have an account yet?",
    register_btn_label: "Create account",
    welcome_back_friend: "Welcome back!",
    have_an_account: "Already have an account?",
    create_account: "Create Account",
    nationality: "Nationality",
    national_id: "National ID",
    passport_id: "Passport ID",
    fullname_arabic: "Full name - Arabic",
    fullname_english: "Full name - English",
    mobile_number: "Mobile Number",
    create_account_btn: "CREATE ACCOUNT",
    save_account_btn: "SAVE ACCOUNT",
    invalid_phone_number: "Invalid phone number",
    required: "required",
    fullname_four_syllable: "Please enter your name as four-syllable",
    lang: "عربي",
    iagree1: "I agree to the",
    iagree2: "Consumer Disclosure Declaration",
    confirm_account: "Confirm your account",
    "4_digits": "Enter the 4-digit code we sent to",
    havent_recived_pin_code: "Haven’t received any code?",
    send_pin_again: "send again",
    otp_done_btn_label: "Done",
    invalid_otp_code: "Invalid OTP code",
    car: "Car",
    travel: "Travel",
    bodyGuard: "Personal Guard",
    darna: "Darna",
    home: "Darna",
    daysToExpire: "days to expire",
    expiredIn: "Expired on",
    expiringIn: "Expiring on",
    policyNumber: "Contract number",
    segmantCode: "Contract Code",
    hello: "Hello",
    close: "Close",
    renew: "renew",
    profile: "Profile",
    myAccount: "MY ACCOUNT",
    personalInfo: "Personal info",
    loginInfo: "Log in info",
    logout: "LOG OUT",
    occupation_insurance_breif:
      "Please spicfy occupation/job name of the person you want to insure.",
    occupation_input_placeholder: "Occupation/job name",
    travel_insurance_title: "New travel insurance",
    personalGuard_insurance_title: "New personal guard insurance",
    age_limit:
      "You must be under 60 Years to get a Personal Guard Insurance Contract.",
    darna_insurance_title: "New darna insurance",
    homeValue_insurance_breif:
      "Hello, please enter the value of your home/house.",
    homeValue_input_placeholder: "Home value",
    homeValue_input_hint: "Please mention the actual value of the property.",
    possessions_insurance_breif:
      "Now, enter the value of possessions in your house hold (furniture, jewelries, pets, plant and electronic devices). ",
    possessions_input_placeholder: "Possessions Value",
    possessions_input_hint: "",
    travel_insurance_breif:
      "Make sure to write the traveler’s name as written in the passport.",
    age_insurance_breif:
      "Hello, please enter the age of the person you want to insure.",
    age_input_hint: "Please enter the age between from 1 to 59.",
    travel_insurance_name_input_placeholder: "Traveler’s name",
    travel_insurance_name_input_hint:
      "Please make sure that the name here matches the exact way written in the passport.",
    travel_type1: "Sky Plus",
    travel_type2: "Sky",
    travel_country_KSA: "KSA",
    travel_country_TurkeyandEgypt: "Turkey and Egypt",
    travel_country_worldWide: "USA, Canada and Australia",
    travel_country_worldWide_excluding: "Other Countries",
    travel_insurance_breif_passport:
      "Now, can you please provide us with the passport number.",
    travel_insurance_passport_input_placeholder: "Passport number",
    age_input_placeholder: "Age",
    travel_insurance_breif_birthdate:
      "We are almost there.. Fill down the birth date please.",
    travel_insurance_breif_trip_long: "How long is the trip?",
    travel_insurance_total_payment: "Your total payment is",
    travel_insurance_total_payment_currency: "JOD",
    travel_insurance_terms_condition: "Terms and conditions",
    travel_insurance_payment_details: "Fill payment details",

    //Registration
    user_city_brief:'Where do you currently live?',
    user_employer_brief: "Where are you currently employed?",
    user_income_brief: "What is your current monthly Income?",
    user_create_brief:"Get ready to create your account",

    // Lababak Service
    name: "Name",
    phone: "Phone",
    note: "Note",
    date: "Date",
    time: "Time",
    car_name: "Car",
    winch: "Towing",
    true: "Yes",
    false: "No",
    enter_location: "Please provide us with your location",
    period1: "8:30 AM - 10:30 AM",
    period2: "10:30 AM - 12:30 PM",
    period3: "12:30 PM - 2:30 PM",
    period4: "2:30 PM - 4:30 PM",
    period5: "4:30 PM - 6:30 PM",
    period6: "6:30 PM - 8:30 PM",
    am: "AM",
    pm: "PM",
    booked: "Booked",
    cancel_lababak: "Cancel",
    update: "Update",
    Confirmed: "Confirmed",
    lababak_appointment: "Lababak Service Appointment",
    lababak_service_insurance_title: "Lababak Service",
    location_breif:
      "Now select where you want our agent to reach you to evaluate the car.",
    cartype_lababak:
      "You had an accident and want to report it? We’re here to help you! Please Select the car that has been involved in the accident.",
    selectDate_breif:
      "Now select the date and time you want to set the appointment.",
    placeholder_date: "Date",
    car_Movement_breif: "Does your car require towing (winch)?",
    addnote_breif: "If you have any comments or remarks, please let us know",
    addNote_input_placeholder: "Note",
    addnote_input_hint: "Add a note",
    policyNumber_breif:
      "You can add cars here that you have access to, such as cars under leasing.",
    policyNumber_input_placeholder: "Contract Number",
    policyNumber_input_hint: "Please add the number Here",
    addcar_lababak: "Is this the car you want to add?",
    report_accident: "REPORT AN ACCIDENT",
    lababak_congratulations_msg:
      "Your Lababak service appointment has been successfully booked. Our team will contact you soon.",
    details:
      "You are one step away from booking your appointment, please confirm the below details and press book or back to edit.",
    lababak_popup_title: "Confirm",
    lababak_popup_message: "Are you sure you want to cancel your appointment?",
    lababak_popup_cancel: "No",
    lababak_popup_action: "Yes",
    add_lababak_car: "Add another car",
    lababak_service_preview_title: "Appointment details",
    preview_details: "Find out your appointment details",
    view_lababak: "View",
    payment_pay_btn_label: "Pay",
    rangeDivider: "   to   ",
    personal_info_title: "Personal info",
    notifications: "Notifications",
    old_password: "Current password",
    new_password: "New password",
    save: "Save",
    new_password_title: "Enter new password",
    password_validation_rule:
      "Make sure it has uppercase, number and special character",
    password_validation_error:
      "Make sure it has uppercase, number, special character, and has at least 6 characters long",
      try_again_btn: "Try Again",

    //Forget Password
    forget_password_title: "Forgot password?",
    forget_password_breif: "Please confirm your email",
    forget_password_btn: "SEND RESET LINK",
    code: "Code",
    forget_password_new_password: "New password",
    forget_password_confirm_password: "Confirm password",
    forget_password_reset_btn: "Reset password",
    password_not_matched: "Passwords don't match",
    forget_password_fill_code:
      "Please enter the code sent to your phone number and your new password",
    forget_password_success_title: "Password Changed!",
    forget_password_success_msg: "Your password has been changed successfully.",
    forget_password_success_back: "BACK TO LOGIN",
    contact_us_title: "Contact us",
    contact_us_breif: "You can stay in touch with us anytime, anywhere...",
    contact_us_fname: "First name",
    contact_us_email: "Email",
    contact_us_message: "Message",
    contact_us_character: "character",
    contact_us_send: "Send",
    find_us_title: "Find us",
    find_us_breif: "Pay us a visit.. We will be glad to have you",
    find_us_company_name: "Jerusalem Insurance JICO",
    find_us_company_main_address:
      "Head Office Address: Al Jandawil, King Abdullah II Street, Building No. 288",
      find_us_company_ClaimsAndAccidents_address: 
      "Vehicle Claims and Accidents Address: Al Jandawil, Umm Metawee Al-Aslameyah Street, Building No. 42",
    find_us_company_address_btn: "TAKE ME THERE",
    openning_hours_title: "Opening hours",
    openning_hours_day1: "Sunday - Thursday",
    openning_hours_time: "8:30 AM - 4:30 PM",
    openning_hours_day2: "Thu",
    no_travel_policy: "You don't have any active travel contracts",
    no_car_policy: "You don't have any active car contracts",
    no_bodyGuard_policy: "You don't have any active personal guard contracts",
    no_home_policy: "You don't have any active darna contracts",
    no_car_policy_btn: "CONTACT OUR AGENT",
    no_travel_policy_btn: "APPLY FOR NEW CONTRACT",
    no_bodyGuard_policy_btn: "APPLY FOR NEW CONTRACT",
    no_home_policy_btn: "APPLY FOR NEW CONTRACT",
    offers_title: "Offers",
    no_offers: `To inquire about our latest offers, please call our Customer Service Center on `,


    //cheque page
    our_customer_service: "Contact our Call Center",
    delievred_title: "Cheque Delivered",
    pick_up_title: "Ready for Pick Up ",
    under_processing_title: "Not Ready Yet!",
    no_cheque_title: "No Cheques Available",
    under_preparation_title: "Your cheque still under preparation.",
    cheque_delivered_msg: `You cheque has been delivered already. If you believe there has been a mixup please contact us.`,
    cheque_pick_up_msg_1: "Your cheque is ready. Please pass by ",
    cheque_pick_up_msg_2: "Jerusalem Insurance HQ",
    cheque_pick_up_msg_3: " to collect it.",
    cheque_under_processing_msg:
      "Your cheque still under processing. Please check again tomorrow. It take 14 days to process the check after the settlement.",
    no_cheque_msg:
      "The code you have does not exist. Please contact our call center for more.",
      cheque_under_preparation_msg:
      "Please check again tomorrow. It take 14 days to process the check after the settlement.",
    arabic: "عربي",
    english: "English",
    cheque_language_title: "Choose Language",


    //Popup Content
    popup_cancel: "Cancle",
    popup_action: "Contact us",
    blacklist_title: "Oops!",
    blacklist_create_policy_title: "Oops!",
    blacklist_message: `This Contract can be renewed only through the company.
    Please visit the company or contact us.`,
    blacklist_create_policy_message: `Your Contract can't be created.
    Please visit the company or contact us.`,
    cartype_title: "Oops!",
    cartype_message: `This Contract can be renewed only through the company.
    Please visit the company or contact us.`,
    claim_title: "Oops!",
    claim_message: `This Contract can be renewed only through the company.
    Please visit the company or contact us.`,
    bank_title: "Oops!",
    bank_message: `This Contract can be renewed only through the company.
    Please visit the company or contact us.`,
    sidebar_home: "Home",
    sidebar_offers: "Offers",
    sidebar_contact_us: "Contact us",
    sidebar_find_us: "Find us",
    next: "next",
    back: "back",
    select: "Select",
    Apply: "APPLY",
    email_title: "Message Sent Successfully",
    email_message: "Thank you for reaching out to us. We have received your message.",
    email_close: "Close",

    policy_title: "Insurance Contract",
    days_to_expire: "days to expire",
    download_policy: "Download Contract",
    effective_date: "Effective Date",
    travel_insurance_congratulations: "Congratulations",
    lababak_insurance_congratulations:
      "Your appointment has been successfully booked!",
    travel_insurance_congratulations_msg:
      "You have successfully issued  new travel insurance contract.. Enjoy your trip",
    personalGuard_insurance_accident_breif:
      "Do you have any previous accidents or previous injuries or practicing dangerous sports exercises?",
    accident_insurance_error_msg:
      "Unfortunately, we cannot create your insurance contract in case you have had previous accidents or previous injuries or practicing dangerous sports exercises.",
    personalGuard_insurance_congratulations_msg:
      "You have successfully applied for new personal guard insurance contract. our team will contact you soon.",
    darna_insurance_congratulations_msg:
      "You have successfully applied for new darna insurance contract. our team will contact you soon.",
    total_amount: "Total Amount Insured",
    car_insurance_congratulations_msg:
      "You have successfully issued your car insurance contract.. Drive safely!",
    car_insurance_apply_congratulations_msg:
      "You have successfully applied for new car insurance contract. our team will contact you soon.",
    back_to_home: "BACK TO HOME",
    travel_insurance_worldwide_breif:
      "Are you travelling to any of these countries (USA, Canada, Japan and Australia) while you are travelling?",
    travel_type_part1: "Hello, please choose the travel insurance type. Click",
    travel_type_part2: " Here",
    travel_type_part3: " for comparison.",
    travel_destination: "Please select your travel destination.",
    car_insurance_renewal: "Car insurance renewal",
    expired_renewal:
      "Unfortunately your insurance contract can only be renewed 45 days before it’s expiry date. Please click below to apply for a new insurance contract.",
    expired_renewal_btn: "APPLY FOR NEW CONTRACT",
    plate_number: "Plate Number",
    document_id: "Document Id",
    vehicle_type_text: "Vehicle Type",
    vehicle_model_text: "Vehicle Model",
    production_year: "Production Year",
    insured_sum: "Insured sum (JOD)",
    user_blocked_car:
      "You cannot renew this insurance contract through the web app",
    user_blocked_travel:
      "You can not issue a travel insurance contract through the web app",
    please_contact_us: "Please contact us for assistance",
    cancel: "Cancel",
    call: "Call",
    payment_failed: "Payment Failed",
    worldwide_yes: "Yes",
    worldwide_no: "No",
    payment_card_number: "Card Number",
    payment_holder_name: "Holder Name",
    payment_expiry_date: "Expiry Date MM/YY",
    payment_CVV: "CVV",
    expires_in: "Expires in",
    personal_info_update_successfully:
      "Personal information updated successfully",
    registration_arabic_name:
      "If you do not know Arabic, please add your name in English",
    add_policy: "ADD NEW CONTRACT",
    Page_cant_be_found: "Page Cannot be found",
    darna_age_erorr_msg:
      "Unfortunately, we cannot create your insurance contract because your age is over 60.",
    darna_insurance_age_breif: "Is your age below 60 years old?",
    download_termsAndCondition: "DOWNLOAD T&C",
    Verified_Policy: "This policy is verified by Jerusalem insurance.",
    Not_Verified_Policy: "This policy is not verified by Jerusalem insurance.",
  },
  ar: {
    login_with_your_account: "تسجيل الدخول بحسابك الخاص",
    email: "البريد الإلكتروني",
    password: "كلمه المرور",
    login_btn_label: "تسجيل الدخول",
    forget_password: "هل نسيت كلمة المرور؟",
    welcome_to_jerusalem_insurance: "مرحباً بكم في القدس للتأمين",
    dont_have_account: "ليس لديك حساب حتى الآن؟",
    register_btn_label: "إنشاء حساب",
    welcome_back_friend: "مرحبًا بعودتك!",
    have_an_account: "هل لديك حساب؟",
    create_account: "أنشئ حسابك",
    nationality: "الجنسية",
    national_id: "الرقم الوطني",
    passport_id: "رقم جواز السفر",
    fullname_arabic: "الاسم الكامل - عربي",
    fullname_english: "الاسم الكامل - انجليزي",
    mobile_number: "رقم الهاتف",
    create_account_btn: "إنشاء حساب",
    save_account_btn: "حفظ الحساب",
    invalid_phone_number: "رقم الهاتف غير صحيح",
    required: "حقل اجباري",
    fullname_four_syllable: "ادخل الاسم من اربع مقاطع",
    wrong_email_format: "عنوان بريد إلكتروني خاطئ",
    lang: "English",
    iagree1: "أنا أوافق على",
    iagree2: "إقرار الإفصاح عن المستهلك",
    confirm_account: "تأكيد الحساب",
    "4_digits": "أدخل الرمز المكون من 4 أرقام الذي أرسلناه إلى",
    havent_recived_pin_code: "لم تحصل على رمز التحقق؟",
    send_pin_again: "إرسال رمز جديد",
    otp_done_btn_label: "إرسال",
    invalid_otp_code: "الرمز غير صحيح",
    car: "مركبات",
    travel: "سفر",
    bodyGuard: "الحارس الشخصي",
    darna: "دارنا",
    daysToExpire: "أيام لتنتهي صلاحيتها",
    expiredIn: "انتهت الصلاحية في",
    expiringIn: "تنتهي الصلاحية في",
    policyNumber: "رقم عقد التأمين",
    hello: "مرحبا",
    close: "إغلاق",
    renew: "تجديد",
    profile: "الملف الشخصي",
    myAccount: "حسابي",
    personalInfo: "معلومات شخصية",
    loginInfo: "معلومات تسجيل الدخول",
    logout: "تسجيل خروج",
    darna_insurance_title: "تأمين دارنا جديد",
    personalGuard_insurance_title: "تأمين حارس الشخصي جديد",
    age_insurance_breif: "مرحبا الرجاء ادخال عمر الشخص الذي ترغب بتأمينة.",
    age_input_hint: " الرجاء ادخال العمر من 1 الى 59",
    age_input_placeholder: "العمر",
    occupation_insurance_breif:
      "الرجاء تحديد الوظيفة أو المهنة للشخص الذي ترغب بتأمينة.",
    occupation_input_hint: " الرجاء ادخال العمر من 1 الى 99",
    occupation_input_placeholder: "اسم الوظيفة أو المهنة",
    homeValue_insurance_breif: " مرحبا قم بإضافة قيمة المنزل.",
    homeValue_input_placeholder: " قيمة المنزل",
    homeValue_input_hint: "يرجى ذكر القيمة الفعلية للممتلكات.",
    possessions_insurance_breif:
      "الآن ، أدخل قيمة الممتلكات في منزلك (الأثاث ، المجوهرات ، الحيوانات أليفة ، النباتات و الأجهزة إلكترونية)    ",
    possessions_input_placeholder: "قيمة المحتويات",
    travel_insurance_title: "تأمين سفر جديد",
    travel_insurance_breif:
      "تأكد من إدخال اسم المسافر كما هو مكتوب في جواز السفر.",
    travel_insurance_name_input_placeholder: "اسم المسافر",
    travel_insurance_name_input_hint:
      "يرجى التأكد من أن الاسم هنا يتطابق تمامًا مع الطريقة المكتوبة في جواز السفر.",

    travel_insurance_breif_passport: "الآن ، هل يمكنك تزويدنا برقم جواز السفر.",
    travel_insurance_breif_birthdate:
      "لقد أوشكنا على الانتهاء .. املأ تاريخ الميلاد من فضلك.",
    travel_insurance_breif_trip_long: "ما مدة هذه الرحلة؟",
    travel_insurance_passport_input_placeholder: "رقم جواز السفر",
    travel_insurance_total_payment: "المبلغ الإجمالي الخاص بك هو",
    travel_insurance_total_payment_currency: "دينار أردني",
    travel_insurance_terms_condition: "الأحكام والشروط",
    travel_insurance_payment_details: "املأ تفاصيل الدفع",
    rangeDivider: "   الى   ",
    payment_pay_btn_label: "الدفع",
    personal_info_title: "المعلومات الشخصية",
    notifications: "إشعارات",
    old_password: "كلمة المرور الحالية",
    new_password: "كلمة المرور الجديدة",
    save: "حفظ",
    new_password_title: "أدخل كلمة مرور جديدة",
    password_validation_rule: "تأكد من احتوائه على أحرف كبيرة ورقم وحرف خاص",
    password_validation_error:
      "تأكد من احتوائه على أحرف كبيرة ورقم وحرف خاص وأنه يتكون من 6 أحرف على الأقل",
    age_limit:
      "يجب أن يكون عمرك أقل من 60 عامًا للحصول على عقد تأمين الحراس الشخصي.",
    forget_password_title: "هل نسيت كلمة المرور؟",
    forget_password_breif: "يرجى تأكيد بريدك الإلكتروني",
    forget_password_btn: "إرسال الرابط",
    forget_password_new_password: "كلمة المرور الجديدة",
    forget_password_confirm_password: "تأكيد كلمة المرور",
    forget_password_reset_btn: "تغيير كلمة المرور",
    password_not_matched: "كلمات المرور غير متطابقة",
    forget_password_fill_code:
      "الرجاء إدخال الرمز المرسل إلى رقم الهاتف وكلمة المرور الجديدة",
    code: "الرمز",
    forget_password_success_title: "تم تغيير كلمة المرور!",
    forget_password_success_msg: "لقد تم تغيير كلمة المرور الخاصة بك بنجاح.",
    forget_password_success_back: " العودة الى تسجيل الدخول",
    contact_us_title: "اتصل بنا",
    contact_us_breif: "يمكنك التواصل معنا في كلّ حين ..",
    contact_us_fname: "الاسم",
    contact_us_email: "البريد الإلكتروني",
    contact_us_message: "الرسالة",
    contact_us_character: "حرف",
    contact_us_send: "إرسال",
    find_us_title: "تجدنا",
    find_us_breif: "تفضل بزيارتنا .. سنكون سعداء باستضافتك",
    find_us_company_name: "القدس للتأمين JICO",
    find_us_company_main_address:
    "العنوان الرئيسي: الجندويل، شارع الملك عبدالله الثاني، عمارة رقم 288",
    find_us_company_ClaimsAndAccidents_address: 
    "عنوان مطالبات وحوادث المركبات: الجندويل، شارع أم مطاوع الأسلمية، عمارة رقم 42",
    find_us_company_address_btn: "خذني الى هناك",
    openning_hours_title: "ساعات العمل",
    openning_hours_day1: "الأحد - الخميس",
    openning_hours_time: "8:30 صباحاً - 4:30 مساءً",
    openning_hours_day2: "الخميس",
    no_travel_policy: "ليس لديك أي عقود تأمين سفر  فعالة",
    no_car_policy: "ليس لديك أي عقود تأمين السيارات  فعالة",
    no_bodyGuard_policy: "ليس لديك أي عقود تأمين حارس الشخصي  فعالة",
    no_home_policy: "ليس لديك أي عقود تأمين دارنا فعالة",
    no_car_policy_btn: "اتصل بنا او اتصل برقمنا",
    no_travel_policy_btn: "قدم طلبًا للحصول على عقد جديد",
    no_bodyGuard_policy_btn: "قدم طلبًا للحصول على عقد جديد",
    no_home_policy_btn: "قدم طلبًا للحصول على عقد جديد",
    offers_title: "عروض",
    no_offers:
      "للتعرف على عروضنا الأخيرة، يرجى الاتصال بمركز خدمة العملاء على ",
    sidebar_home: "الرئيسية",
    sidebar_offers: "عروض",
    sidebar_contact_us: "اتصل بنا",
    sidebar_find_us: "تجدنا",
    next: "التالي",
    back: "السابق",
    select: "اختار",
    Apply: "تقدم",
    email_title: "تم إرسال الرسالة بنجاح",
    email_message: "شكرًا لتواصلك معنا. لقد استلمنا رسالتك.",
    email_close: "أغلاق",

    policy_title: "عقد التأمين",
    days_to_expire: "يوم/أيام لتنتهي الصلاحية",
    download_policy: "تحميل عقد التأمين",
    effective_date: "تاريخ السريان",
    travel_type1: "منتج سكاي بلس",
    travel_type2: "منتج سكاي",
    travel_country_KSA: "السعودية",
    travel_country_TurkeyandEgypt: "تركيا و مصر",
    travel_country_worldWide: "الولايات المتحدة الأمريكية او كندا او أستراليا",
    travel_country_worldWide_excluding: "بلدان اخرى",
    travel_insurance_congratulations: "تهانينا",
    lababak_insurance_congratulations: " تم حجز موعدك بنجاح!",
    travel_insurance_congratulations_msg:
      "لقد نجحت في إصدار عقد تأمين سفر جديد .. استمتع برحلتك",
    personalGuard_insurance_accident_breif:
      "هل تعرضت لأي حوادث سابقة أو إصابات سابقة أو تمارس تمارين رياضية خطيرة؟",
    accident_insurance_error_msg:
      "للأسف ، لا يمكننا إصدار عقد تأمينك في حال تعرضك لحوادث سابقة او اصابات سابقة او تمارس تمارين رياضية خطيرة.",
    personalGuard_insurance_congratulations_msg:
      "لقد نجحت في تقديم طلب للحصول على عقد تأمين حارس الشخصي. سيقوم مركز خدمة العملاء بالتواصل معك بأقرب وقت.",
    darna_insurance_congratulations_msg:
      "لقد نجحت في تقديم طلب للحصول على عقد تأمين دارنا. سيقوم مركز خدمة العملاء بالتواصل معك بأقرب وقت.",
    total_amount: "قيمة تأمين الإجمالية",
    car_insurance_congratulations_msg:
      "لقد نجحت في إصدار عقد تأمين سيارتك .. قد بأمان!",
    car_insurance_apply_congratulations_msg:
      "لقد نجحت في تقديم طلب للحصول على عقد تأمين لسيارتك. سيقوم مركز خدمة العملاء بالتواصل معك بأقرب وقت.",


    // Lababak Service
    name: "الاسم",
    phone: "رقم الهاتف",
    note: "ملاحظة",
    date: "التاريخ",
    time: "الوقت",
    book: "حجز",
    car_name: "السيارة",
    winch: "ونش",
    true: "نعم",
    false: "لا",
    enter_location: "أدخل الموقع",
    period1: "8:30 ص - 10:30 ص",
    period2: "10:30 ص - 12:30 م",
    period3: "12:30 م - 2:30 م",
    period4: "2:30 م - 4:30 م",
    period5: "4:30 م - 6:30 م",
    period6: "6:30 م - 8:30 م",
    am: "ص",
    pm: "م",
    booked: "محجوز",
    cancel_lababak: "إلغاء الموعد",
    Confirmed: "تم تأكيد الموعد",
    update: "تحديث",
    lababak_appointment: "موعد خدمة لبابك",
    lababak_service_insurance_title: "خدمة لبابك",
    location_breif:
      "حدد الآن المكان الذي تريد أن يصلك إليه موظف خدمة لبابك للكشف على أضرار المركبة وتقييمها.",
    cartype_lababak:
      "هل تعرضت لحادث وترغب في الإبلاغ عنه؟ نحن هنا لمساعدتك!  يرجى تحديد نوع السيارة التي تعرضت للحادث.",
    selectDate_breif:
      "لطفاً، قم بتحديد التاريخ والوقت الذي يناسبك لزيارة موظفنا لك",
    placeholder_date: "تاريخ",
    car_Movement_breif: "هل تحتاج مركبتك إلى ونش لنقلها من مكان وقوع الحادث؟",
    addnote_breif:
      "إذا كان لديك أي تعليقات أو ملاحظات أو تعليمات تخص مركبتك، فيرجى إعلامنا بها.",
    addNote_input_placeholder: "ملاحظة",
    addnote_input_hint: "أضف ملاحظة",
    report_accident: "الإبلاغ عن الحادث",
    lababak_congratulations_msg:
      "لقد نجحت في حجز موعد خدمة لبابك! سيتصل بك فريقنا قريباً.",
    details:
      "أنت على بعد خطوة من حجز موعد. يرجى تأكيد التفاصيل واضغط حجز أو اضغط السابق لتعديلها.",
    lababak_popup_title: "تأكيد الإلغاء",
    lababak_popup_message: "هل انت متأكد من إلغاء الموعد؟",
    lababak_popup_cancel: "لا",
    lababak_popup_action: "نعم",
    add_lababak_car: "اضف سيارة",
    lababak_service_preview_title: "تفاصيل موعدك",
    preview_details: "اطلع على تفاصيل موعدك",
    view_lababak: "تفاصيل",

    //cheque page
    our_customer_service: "خدمة العملاء",
    delievred_title: "تم تسليم الشيك",
    pick_up_title: "جاهز للإستلام",
    under_processing_title: "ليست جاهزة بعد!",
    under_preparation_title: "الشيك الخاص بك لا يزال قيد الإعداد",
    no_cheque_title: "لا يوجد شيكات متاحة",
    cheque_delivered_msg: `تم تسليم الشيك الخاص بك بالفعل. إذا كنت تعتقد بوجود خطأ ، فيرجى الاتصال بنا.`,
    cheque_pick_up_msg_1: "  الشيك جاهز. الرجاء المرور الى",
    cheque_pick_up_msg_2: "مقر القدس للتأمين ",
    cheque_pick_up_msg_3: "لأخذه. ",
    cheque_under_processing_msg:
      "الشيك الخاص بك لا يزال قيد تجهيز. يرجى التحقق مرة أخرى غدا. تستغرق عملية تجهيز الشيك 14 يومًا بعد التسوية. ",
    no_cheque_msg:
      "الرمز الذي لديك غير موجود. يرجى الاتصال بمركز الاتصال لدينا لمزيد من المعلومات. ",
    cheque_under_preparation_msg:
      "يرجى التحقق مرة أخرى غدا. تستغرق عملية تجهيز الشيك 14 يومًا بعد التسوية.",
    arabic: "عربي",
    english: "English",
    cheque_language_title: "اختر اللغة",

    //Popup Content
    popup_cancel: "إلغاء",
    popup_action: "اتصل بنا",
    blacklist_title: "عذراً!",
    blacklist_create_policy_title: "عذراً!",
    blacklist_message: `هذا العقد يتم تجديده فقط من خلال الشركة! 
     يرجى زيارتنا أو التواصل معنا`,
    blacklist_create_policy_message: ` لا يمكن أنشاء عقد تأمينك
      يرجى زيارتنا أو التواصل معنا`,
    cartype_title: "عذراً!",
    cartype_message: `هذا العقد يتم تجديده فقط من خلال الشركة! 
    يرجى زيارتنا أو التواصل معنا`,
    claim_title: "عذراً!",
    claim_message: `هذا العقد يتم تجديده فقط من خلال الشركة! 
    يرجى زيارتنا أو التواصل معنا`,
    bank_title: "عذراً!",
    bank_message: `هذا العقد يتم تجديده فقط من خلال الشركة! 
    يرجى زيارتنا أو التواصل معنا`,
    back_to_home: "العودة الى الصفحة الرئيسية",
    travel_insurance_worldwide_breif:
      "هل تسافر إلى أي من هذه البلدان (الولايات المتحدة الأمريكية وكندا واليابان وأستراليا) أثناء سفرك؟",
    travel_type_part1: "مرحبًا ، الرجاء أختيار نوع تأمين السفر. انقر",
    travel_type_part2: " هنا",
    travel_type_part3: " للمقارنة.",
    travel_destination: "يرجى تحديد وجهة سفر؟",
    car_insurance_renewal: "تجديد تأمين السيارة",
    expired_renewal:
      "للأسف عقد التأمين يمكن تجديدها قبل 45 يوم من تاريخ انتهائها. الرجاء التقدم لطلب  تأمين جديد.",
    expired_renewal_btn: "قدم طلبًا للحصول على عقد تأمين جديد",
    plate_number: "رقم اللوحة",
    document_id: "رقم تعريف عقد التأمين",
    vehicle_type_text: "نوع المركبة",
    vehicle_model_text: "موديل السيارة",
    production_year: "سنة الصنع",
    insured_sum: "مبلغ التأمين (دينار اردني)",
    user_blocked_car: "لا يمكنك تجديد هذا العقد من خلال الموقع",
    user_blocked_travel: "لا يمكنك إصدار العقد تأمين السفر من خلال الموقع",
    please_contact_us: "يرجى الاتصال بنا للحصول على المساعدة",
    cancel: "إلغاء",
    call: "إتصال",
    payment_failed: "عملية الدفع فشلت",
    worldwide_yes: "نعم",
    worldwide_no: "لا",
    payment_card_number: "رقم البطاقة",
    payment_holder_name: "اسم حامل البطاقة",
    payment_expiry_date: "تاريخ الانتهاء  MM/YY",
    payment_CVV: "رقم الكود",
    expires_in: "ينتهي في",
    personal_info_update_successfully: "تم تحديث المعلومات الشخصية بنجاح",
    registration_arabic_name:
      "إذا كنت لا تعرف اللغة العربية يرجى إضافة اسمك باللغة الإنجليزية",
    add_policy: "أضف عقد تأمين جديد",
    Page_cant_be_found: "لا يمكن العثور على الصفحة",
    darna_age_erorr_msg:
      "الاسف لا يمكننا إصدار عقد تأمينك الانك تجاوزت عمر 60 سنة. ",
    darna_insurance_age_breif: "هل عمرك أقل من 60 سنة؟",
    try_again_btn: "حاول مرة اخرى",
  },
};
