import { GET_URL_PATH_FIRST_PART, GET_URL_PATH_LAST_PART } from './variables';
import { languages } from './languages.js';
import keycode from 'keycode'
import Settings from 'services/settings.service';
import { useLocation } from "react-router-dom";

////////////////////////
const isLangExist=(path)=>{
    let initPath = path.substr(0,4);
    return initPath === '/en/' || initPath === '/ar/'
}
////////////////////////
export const getFullPathWithoutLang = (path = window.location.pathname) => {
    return isLangExist(path)?path.substr(4):path.substr(1);
}
////////////////////////

export const getPath = (path = window.location.pathname, part = GET_URL_PATH_LAST_PART, calee=null) => {

    if(!isLangExist(path)){
        return `${path.substr(1)}`
    }
    else{
        let newPath = isLangExist(path) ? path.substr(4):path;
        if (part === GET_URL_PATH_FIRST_PART) {
            const indexOfBackSlash = newPath.indexOf('/');
            if (indexOfBackSlash !== -1) {
                return newPath.substring(0, indexOfBackSlash)
            }
            return newPath;
        }
        else { ///GET_URL_PATH_LAST_PART
            if (newPath.lastIndexOf('/') === newPath.length - 1) {
                return newPath.substr(newPath.lastIndexOf('/') + 1, newPath.length - 1)
            }
            return newPath.substr(newPath.lastIndexOf('/') + 1);
        }
    }


    // switch (newPath) {
    //     case '/':
    //       return DEFAULT_LANG;
    //     case '/en':
    //     case '/en/':
    //       return 'Latin';
    //     case '/ar':
    //     case '/ar/':
    //       return 'Arabic';
    //     default:
    //       return DEFAULT_LANG;
    //   }

    
};
////////////////////////
export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
////////////////////////
export const getLang = (path) => {
    let newPath = path.substr(0, 4);
    switch (newPath) {
        case '/':
            return getDefaultLanguage();
        case '/en':
        case '/en/':
            return 'en';
        case '/ar':
        case '/ar/':
            return 'ar';
        default:
            return getDefaultLanguage();
    }
}
////////////////////////
export const getDefaultLanguage = (lang = null) => {

    if (lang) {
        return { ...languages[lang] }
    }

    const keys = Object.keys(languages);
    const keysLength = keys.length;
    let initState = {}
    for (let i = 0; i < keysLength; i++) {
        if (languages[keys[i]].default) {
            initState = { ...languages[keys[i]] };
            break;
        }
    }
    return initState;
}
////////////////////////
export const promiseHandler = async (promise) => {
    try {
        const response = await promise();
        return [response.data, null]
    }
    catch (error) {
        const errorMsg = handleError(error);
        return [null, errorMsg]
    }

}
////////////////////////////
export const isAllowedKey = (e) =>
  (e.metaKey && e.code === 'KeyA') ||
  (e.metaKey && e.code === 'KeyV') ||
  (e.metaKey && e.code === 'KeyC') ||
  keycode(e) === 'delete' ||
  keycode(e) === 'backspace' ||
  keycode(e) === 'enter' ||
  keycode(e) === 'tab' ||
  keycode(e) === 'left command' ||
  keycode(e) === 'right command' ||
  keycode(e) === 'left' ||
  keycode(e) === 'right' ||
  ((keycode(e) === '0' ||
    keycode(e) === '1' ||
    keycode(e) === '2' ||
    keycode(e) === '3' ||
    keycode(e) === '4' ||
    keycode(e) === '5' ||
    keycode(e) === '6' ||
    keycode(e) === '7' ||
    keycode(e) === '8' ||
    keycode(e) === '9' ||
    keycode(e) === 'numpad 0' ||
    keycode(e) === 'numpad 1' ||
    keycode(e) === 'numpad 2' ||
    keycode(e) === 'numpad 3' ||
    keycode(e) === 'numpad 4' ||
    keycode(e) === 'numpad 5' ||
    keycode(e) === 'numpad 6' ||
    keycode(e) === 'numpad 7' ||
    keycode(e) === 'numpad 8' ||
    keycode(e) === 'numpad 9') &&
    !/\D/.test(+e.key));
////////////////////////////

export const isDigit = (e) =>
  keycode(e) === '0' ||
  keycode(e) === '1' ||
  keycode(e) === '2' ||
  keycode(e) === '3' ||
  keycode(e) === '4' ||
  keycode(e) === '5' ||
  keycode(e) === '6' ||
  keycode(e) === '7' ||
  keycode(e) === '8' ||
  keycode(e) === '9' ||
  keycode(e) === 'numpad 0' ||
  keycode(e) === 'numpad 1' ||
  keycode(e) === 'numpad 2' ||
  keycode(e) === 'numpad 3' ||
  keycode(e) === 'numpad 4' ||
  keycode(e) === 'numpad 5' ||
  keycode(e) === 'numpad 6' ||
  keycode(e) === 'numpad 7' ||
  keycode(e) === 'numpad 8' ||
  keycode(e) === 'numpad 9';

////////////////////////////
export const errorMsg = error => {
    if (error.message) {
        return error.message;
    }
    return error;
}
////////////////////////////
export const handleError = error => {
    let errorMsg = error;
    if (error.response && error.response.data && error.response.data.message) {
        return {
            code: error.response.data.code,
            message: error.response.data.message
        }
    }
    if (error.response && error.response.data) {
        return error.response.data
    }
    return errorMsg;
};
////////////////////////////
export const oppositeDirection = (dir) => {
    return dir === 'ltr' ? 'rtl' : 'ltr'
}
////////////////////////////
export const standardFormatDate = (date, addSuffix = false, dir) => {
    const dateYear = new Date(date).getFullYear();
    const dateMonth = new Date(date).getMonth() + 1;
    const dateDay = new Date(date).getDate();
    if (dir === 'ltr'){
        return `${dateDay < 10 ? `0${dateDay}` : dateDay}-${dateMonth < 10 ? `0${dateMonth}` : dateMonth}-${dateYear}${addSuffix ? Settings.REACT_APP_FORMAT_DATE_SUFFIX : ''}`
    }else {
        return `${dateYear}-${dateMonth < 10 ? `0${dateMonth}` : dateMonth}-${dateDay < 10 ? `0${dateDay}` : dateDay}${addSuffix ? Settings.REACT_APP_FORMAT_DATE_SUFFIX : ''}`
    }
}
////////////////////////////
export const monthDayFormatDate = (date, addSuffix = false) => {
    const dateMonth = new Date(date).getMonth() + 1;
    const dateDay = new Date(date).getDate();
    return `${dateMonth < 10 ? `0${dateMonth}` : dateMonth}-${dateDay < 10 ? `0${dateDay}` : dateDay}${addSuffix ? Settings.REACT_APP_FORMAT_DATE_SUFFIX : ''}`
}
///////////////////////////
// datepart: 'y', 'm', 'w', 'd', 'h', 'n', 's'
export const dateDiff = (datepart, fromdate, todate) => {

    datepart = datepart.toLowerCase();
    let diff = todate - fromdate;
    let divideBy = {
        w: 604800000,
        d: 86400000,
        h: 3600000,
        n: 60000,
        s: 1000
    };

    return Math.floor(diff / divideBy[datepart]);
}

export const getDateFormated = (data) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };