import { v4 as uuidv4 } from 'uuid';
import { set_session, set_device_id } from './http.service';
import { getStorageElement, setStorageElement, clearStorageElements } from './storage.service';
import { getUserAuth, getUserLogin } from 'api/user.api'


const generateUUID = () => {
    const uuidCode = uuidv4();
    // HTTP.set_device_id(uuidCode);
    // setStorageElement('uuid', uuidCode)
    return uuidCode;
}
///////////////////////////////////////

const updateSession = (uuid, authToken) => {

    setStorageElement('uuid', uuid);
    setStorageElement('authToken', authToken);
    set_session(authToken);
    set_device_id(uuid);
    // 4- update session 
    // if (!HTTP.get_session()) {
    //     HTTP.set_session(authToken);
    //     HTTP.set_device_id(uuid)
    // }
}
/////////////////////////////////////////
export const initiateUser = async () => {
    // 1- get uuid and authToken from sessionStorage
    let uuid = getStorageElement('uuid');
    let authToken = getStorageElement('authToken');

    // 2- check if uuid  exist
    if (!uuid) {
        uuid = generateUUID();
        const userAuthReponse = await getUserAuth({ "device_id": uuid });
        authToken = userAuthReponse.data.auth_token;
        updateSession(uuid, authToken)
        return null;
    }

    // 3- check if session exist
    if (!authToken) {
        const userAuthReponse = await getUserAuth({ "device_id": uuid });
        authToken = userAuthReponse.data.auth_token;
        updateSession(uuid, authToken);
        return null;
    }

    updateSession(uuid, authToken)

    // 4- call /user/current api
    return getUserLogin(authToken);
}
/////////////////////////////////////////
export const logout = (lang) => {
    clearStorageElements();
    window.location = `${window.location.origin}/${lang}`;
};
/////////////////////////////////////////
