export const removeStorageElement = (key, location = 'local') => {

    if (location === 'session') {
        sessionStorage.removeItem(key);
        return;
    }
    localStorage.removeItem(key);
};

export const clearStorageElements = () => {
    sessionStorage.clear();
    localStorage.clear();
};

export const setStorageElement = (key, value, location = 'local') => {
    const content = JSON.stringify(value)
    if (location === 'session') {
        sessionStorage.setItem(key, content);
        return;
    }
    localStorage.setItem(key, content);

};

export const getStorageElement = (key, location = 'local') => {
    if (location === 'session') {
        return JSON.parse(sessionStorage.getItem(key));
    }
    return JSON.parse(localStorage.getItem(key));
};