import "babel-polyfill";
import {
  Suspense,
  useEffect,
  useMemo,
  memo,
  useContext,
  useReducer,
  useCallback
} from 'react';
import Routes from "routes";
import Layout from "layout/layout";
import ScrollToTop from "components/shared/scroll-top";


import { getPath } from 'utility'
import { useLocation } from 'react-router-dom';
import { GET_URL_PATH_FIRST_PART } from 'variables';
import { initiateUser } from 'services/user.service';
import { removeStorageElement } from 'services/storage.service';
import UserContext from 'stores/user.context';
import LayoutContext from 'stores/layout.context';

import Spinner from 'components/shared/spinner';
import { promiseHandler, errorMsg } from 'utility';
import {
  START_API,
  SUCCESS_API,
  FAILED_API
} from 'variables';

let template = null;

const initState = {
  loading: true,
  error: null
}

const appReducer = (state, action) => {
  switch (action.type) {
    case START_API:
      return {
        ...state,
        loading: true,
        error: null
      }
    case SUCCESS_API:
      return {
        ...state,
        loading: false,
        error: null
      }
    case FAILED_API:
      if (action.error.message) {
        return {
          ...state,
          loading: false,
          error: action.error.message,
          errorCode: action.error.code
        }

      }
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state
  }
}
/////////////////////////////////////////////
const App = memo((props) => {

  const location = useLocation();
  const { setUserInfo } = useContext(UserContext);
  const { lang } = useContext(LayoutContext);

  const [appState, dispatch] = useReducer(appReducer, initState);

  const path = useMemo(()=>{
    return getPath(location.pathname, GET_URL_PATH_FIRST_PART,"App");
  },[location.pathname]);


  // removeStorageElement
  const generateNewSession = useCallback(async () => {
    removeStorageElement('uuid')
    removeStorageElement('authToken')
    dispatch({ type: START_API });
    await promiseHandler(() => initiateUser());
    dispatch({ type: SUCCESS_API });
    window.location = `${window.location.origin}/${lang}`;
  }, [lang])

  /////////////////////////////////////////////
  const initiateUserData = useCallback(async () => {
    dispatch({ type: START_API });
    const [userInfo, error] = await promiseHandler(() => initiateUser());
    if (error && error.code && error.code === 'invalid_session_id') {
      generateNewSession();
      return;
    }

    if (error) {
      dispatch({ type: FAILED_API, error: errorMsg(error) });
      return;
    }

    setUserInfo(userInfo)
    dispatch({ type: SUCCESS_API });

  }, [setUserInfo, generateNewSession])
  /////////////////////////////////////////////

  useEffect(() => {
    initiateUserData();
  }, [initiateUserData])
  ////////////////////////////////////
  const layoutTemplate = useMemo(() => {
    switch (path) {
      case 'dashboard':
        template = 'DashboardLayout'
        break;
      case 'signin-signup' :
      case 'en' :
      case '' :
      case 'ar' :
      case 'cheques':
        template = 'LoginLayout'
        break;
      case 'car-insurance-renewal':
      case 'create-policy':
      case 'profile':
      case 'offers':
      case 'policies':
      case 'payment-result':
        case 'create-lababak-appointment':
        template = 'BlankLayout'
        break;
        
      default:
        template = 'DefaultLayout'
    }

    let content = (
      <ScrollToTop>
        <Layout template={'loading'}>
          <Suspense fallback={<div style={{ minHeight: '100vh' }}></div>}>
            <Spinner />
          </Suspense>
        </Layout>
      </ScrollToTop>
    );
    if (!appState.loading) {
      content = (
        <ScrollToTop>
          <Layout template={template}>
            <Suspense fallback={<div style={{ minHeight: '100vh' }}></div>}>
              <Routes />
            </Suspense>
          </Layout>
        </ScrollToTop>
      )
    }
    return content;
  }, [path, appState.loading]);

  return layoutTemplate;

});

export default App;