import { Suspense, memo, lazy } from 'react';
import Spinner from 'components/shared/spinner';

let layout = null;

const SigninSignoutLayout = lazy(() => import(/* webpackChunkName: "Default layout" */ `./signin-signout`));
const DashboardLayout = lazy(() => import(/* webpackChunkName: "Dashboard layout" */ `./dashboard`));
const BlankLayout = lazy(() => import(/* webpackChunkName: "Blank layout" */ `./blank`));
const LoginLayout = lazy(() => import(/* webpackChunkName: "Blank layout" */ `./login`));


const Layout = memo((props) => {
  const { template, children } = props;

  switch (template) {
    case 'DashboardLayout':
      layout = <DashboardLayout>{children}</DashboardLayout>
      break;
    case 'BlankLayout':
      layout = <BlankLayout>{children}</BlankLayout>
      break;
    case 'LoginLayout':
      layout = <LoginLayout>{children}</LoginLayout>
      break;
    case 'loading':
      layout = <Spinner />
      break;
    default:
      layout = <SigninSignoutLayout>{children}</SigninSignoutLayout>
  }
  return (
    <Suspense fallback={null}>{layout}</Suspense>
  )
})
export default Layout;