import { createContext, useReducer, useCallback } from 'react';
import { get_session } from 'services/http.service'
import { SET_USER_INFO } from 'variables';

////////////////////////////////////////
const UserContext = createContext({
    user: null,
    /* 
        listing functions her not nececary but 
        it will help us for editor autocomplete
    */
    setUserInfo: (data) => { },
});
////////////////////////////////////////
const userReducer = (state, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            if (!state.user) {
                const user = { ...action.info, 'authToken': get_session() }
                return {
                    ...state,
                    user
                }
            }
            return {
                ...state,
                user: { ...action.info }
            }
        default:
            return state
    }
}
////////////////////////////////////////
const initState = {
    user: null
}
export const UserContextProvider = (props) => {

    const [userState, dispatch] = useReducer(userReducer, initState);

    //////////////////////////////////
    const setUserInfo = useCallback((data) => {
        dispatch({ type: SET_USER_INFO, info: data })
    }, [])
    //////////////////////////////////

    const context = {
        user: userState.user,
        setUserInfo: setUserInfo
    }

    return (
        <UserContext.Provider value={context}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext;