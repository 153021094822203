export const languages = {
    "en": {
        "lang": "en",
        "label": "English",
        "direction": "ltr",
        "defaultDirection": "left",
        "oppositeDirection": "right",
        "fontFamily": "Latin",
        "default": true
    },
    "ar": {
        "lang": "ar",
        "label": "Arabic",
        "direction": "rtl",
        "defaultDirection": "right",
        "oppositeDirection": "left",
        "fontFamily": "Arabic",
        "default": false
    }
}